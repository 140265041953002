import React from 'react'
import {Carousel} from 'antd'

import QuoteItem from 'molecules/TestimonialItem'

import './TestimonialCarousel.scss'

/**
 * Render the TestimonialCarousel component.
 * @see     {@link https://ant.design/components/carousel/}
 * @author  Content Pilot
 * @return {Element} The TestimonialCarousel component.
 */
export default function TestimonialCarousel() {
  return (
    <Carousel
      autoplay={true}
      autoplaySpeed={7000}
      speed={700}
      draggable={true}
      style={{cursor: 'pointer'}}
    >
      <QuoteItem author="Patrick Fuller, VP" position="ALM Intelligence">
        Kudos to my longtime friends Deborah McMurray, Keith Wewe, and their
        outstanding team ... on the 2020 edition of their &quot;10 Foundational
        Best Practices&quot; and their review of the Am Law 50&apos;s websites.
        This is meticulously researched and incredibly fascinating - work
        exceedingly well done. Required reading for our team at ALM
        Intelligence.{' '}
        <a href="https://lnkd.in/eZvQWha" target="_blank" rel="noreferrer">
          You can get your copy here
        </a>
        .
      </QuoteItem>
      <QuoteItem author="Rachel Guy, Director of Marketing" position="Winstead">
        Winstead&apos;s business development/marketing team has followed Content
        Pilot&apos;s &quot;Foundational Best Practices&quot; research since
        2005. We have grown to depend on this research to help us plan and
        elevate our website and content marketing strategy. The research offers
        practical insights, tips and a framework for building an effective
        website that delivers value to our key audiences.
      </QuoteItem>
      <QuoteItem
        author="Leon Loganathan, Partner"
        position="Ward Keller, Darwin, Australia"
      >
        This study was very useful in helping us identify what works on a law
        firm website.
      </QuoteItem>
      <QuoteItem
        author="Patrick McKenna"
        position="McKenna Associates Inc"
        extra={
          <span>
            Latest book{' '}
            <a href="https://lnkd.in/grsexeq" target="_blank" rel="noreferrer">
              Strategy Innovation: Getting To The Future First
            </a>
          </span>
        }
      >
        I thought your website research was hugely insightful and have
        recommended the study to a number of firms, telling them that they
        needed to download their own copy and give it a serious read.
      </QuoteItem>
      <QuoteItem
        author="Allen Fuqua"
        position="Allen Fuqua Strategies; Former AmLaw 100 and 200 CMO"
      >
        Ten Foundational Best Practices, Content Pilot’s website research, is a
        seminal support tool for any senior marketing executive who needs to
        understand where and what his or her website needs to focus on to
        maximize its performance in the marketplace. I would suggest reviewing
        it before you begin a website project and then periodically as you
        evaluate your website. It will save you money, confusion and
        embarrassment.
      </QuoteItem>
      <QuoteItem
        author="Eric Fletcher"
        position="Business Development Consultant/Advisor"
        extra={
          <span>
            Author -{' '}
            <a
              href="https://www.marketingbrainfodder.com/"
              target="_blank"
              rel="noreferrer"
            >
              MarketingBrainFodder.com
            </a>{' '}
            and former AmLaw 200 CMO
          </span>
        }
      >
        There are three major challenges baked in to the creation of a website
        for a large firm: (1) the diverse interests of hundreds or thousands of
        individual partners; (2) the fact that these individuals tend to believe
        what constitutes a good legal brief equates to effective business
        communication; and (3) the degree to which much of the process often
        hinges on subjective views. Content Pilot’s research around best
        practices, quantifying and analyzing what actually connects with clients
        is of enormous value to any firm wanting to employ solid principles in a
        website project. And it positions the CP team as the ideal project
        partner, on an informed cutting edge in an arena that is evolving at
        warp speed.
      </QuoteItem>
      <QuoteItem author="Paul Bonner" position="Director of Marketing, Venable">
        I was an early adopter and user of Content Pilot’s groundbreaking Ten
        Foundational Best Practices research when the law firm where I was at
        nearly 15 years ago kicked-off a site redesign. I hired Content Pilot
        then and have several times since to analyze my firms’ sites. Their
        strategic and tactical roadmap covered virtually every aspect of a
        high-performing site and was extremely valuable. I wouldn’t think of
        designing a website without it.
      </QuoteItem>
    </Carousel>
  )
}
