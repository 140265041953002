import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ScrollableAnchor from './../ScrolleableAnchor'

import {anchor, icon} from './ButtonScroll.module.scss'

/**
 * Render the ButtonScroll component.
 * @see                          {@link https://ant.design/components/button/}
 * @author                       Content Pilot
 * @param  {object}  props        The component attributes as props.
 * @param  {string}  props.target The button target.
 * @param  {string}  props.offset The button offset.
 * @return {Element}              The ButtonScroll component.
 */
export default function ButtonScroll({target, offset}) {
  return (
    <ScrollableAnchor
      target={target}
      offset={offset}
      className={anchor}
      ariaLabel="Scroll button"
    >
      <span>Scroll</span>
      <FontAwesomeIcon
        icon={['fal', 'long-arrow-down']}
        className={icon}
        aria-label="Scroll icon"
      />
    </ScrollableAnchor>
  )
}

ButtonScroll.prototype = {
  target: PropTypes.string.isRequired,
  offset: PropTypes.string
}
