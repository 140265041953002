// extracted by mini-css-extract-plugin
export var bounce = "HomeNavigation-module--bounce--6bfc0";
export var dotMoveLeft = "HomeNavigation-module--dot-move-left--62c42";
export var dotMoveRight = "HomeNavigation-module--dot-move-right--8f921";
export var gradient = "HomeNavigation-module--gradient--497d8";
export var icon = "HomeNavigation-module--icon--025c3";
export var indicator = "HomeNavigation-module--indicator--8cbc4";
export var left = "HomeNavigation-module--left--f20bc";
export var nav = "HomeNavigation-module--nav--d133a";
export var right = "HomeNavigation-module--right--26c9b";
export var separatorWidth = "HomeNavigation-module--separator-width--04834";