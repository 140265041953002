import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {leftQuote, rightQuote, text, foot} from './TestimonialItem.module.scss'

/**
 * Render the TestimonialItem component.
 * @see                            {@link https://ant.design/components/list/}
 * @author                         Content Pilot
 * @param  {object}  props          The component attributes as props.
 * @param  {Node}    props.children Child component(s) to render.
 * @param  {string}  props.author   The quote author.
 * @param  {string}  props.position The quote position.
 * @param  {Node}    props.extra    The quote extra element.
 * @return {Element}                The TestimonialItem component.
 */
export default function TestimonialItem({children, author, position, extra}) {
  return (
    <div>
      <FontAwesomeIcon
        icon={['fas', 'quote-left']}
        aria-label="Quote left icon"
        className={leftQuote}
      />
      <p className={text}>{children}</p>
      <FontAwesomeIcon
        icon={['fas', 'quote-right']}
        aria-label="Quote right icon"
        className={rightQuote}
      />
      <p className={foot}>
        <span>{author}</span>
        <span>{position}</span>
        {extra && extra}
      </p>
    </div>
  )
}

TestimonialItem.propTypes = {
  children: PropTypes.node.isRequired,
  author: PropTypes.string,
  position: PropTypes.string,
  extra: PropTypes.node
}
