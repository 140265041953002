import React from 'react'
import {Typography} from 'antd'

import Content from 'organisms/Content'
import Main from 'organisms/Main'
import SEO from 'molecules/Seo'
import MenuSecondary from 'molecules/MenuSecondary'
import HistoricalScores from 'molecules/HistoricalScores'
import TestimonialCarousel from 'molecules/TestimonialCarousel'
import HomeNavigation from 'molecules/HomeNavigation'
import ButtonScroll from 'atoms/ButtonScroll'

const {Title} = Typography

import {
  content,
  overlay,
  group1,
  group2,
  group3,
  black,
  white,
  chart
} from './index.module.scss'

/**
 * Index Page
 * @return {Element} The Index page.
 */
export default function IndexPage() {
  return (
    <Content>
      <SEO title="Home" />
      <MenuSecondary />
      <Main className={content}>
        <div className={overlay}></div>
        <div className={group1}>
          <Title level={1}>Ten Foundational Best Practices</Title>
          <p>
            The &quot;Ten Foundational Best Practices&quot; are the must-have
            features and functionality for all law firm websites, regardless of
            size, practice-mix and reach. Stop guessing. There are 71 attributes
            that you can reference when planning your website refresh or
            redesign. They change over time - based on changes in visitor
            expectations and how buyers of legal services buy, and new standards
            in the web industry. Your targeted and coveted visitors have high
            expectations that your law firm will deliver the answers to their
            burning needs and pressing questions.{' '}
            <strong>Are you delivering?</strong>
          </p>
          <p>
            Content Pilot created the now widely-lauded Ten FBPs and has
            conducted 7 comprehensive analyses of the AmLaw 100 or AmLaw Global
            50 firm websites. The research began in 2005 and the last study was
            Q4 2019.
          </p>
          <p>
            You can access the latest results, trends and insights on this site,
            but we also encourage you to download the latest comprehensive{' '}
            <a
              href="https://www.contentpilot.com/amlawglobal50websites2020/"
              target="_blank"
              rel="noreferrer"
            >
              White Paper here
            </a>
            .
          </p>
          <ButtonScroll target="homepage-anchor" />
        </div>
        <div id="homepage-anchor" className={group2}>
          <Title className={white} level={2}>
            quotable
          </Title>
          <TestimonialCarousel />
        </div>
      </Main>
      <div className={group3}>
        <Title className={black} level={2}>
          trends over the years
        </Title>
        <p>
          Follow how AmLaw 100 and AmLaw Global 50 firms scored over the 7
          research studies on each FBP. Interactivity and Mobility were added in
          2007 and 2013, respectively, so they start at zero. Hover over the
          line for each year and the scores for that year will appear.
        </p>
        <div className={chart}>
          <HistoricalScores />
        </div>
      </div>
      <HomeNavigation />
    </Content>
  )
}
