/**
 * Returns the historical scores data.
 */
export const historicalScores = [
  {
    id: 'usability',
    color: '#da0a82',
    data: [
      {
        x: 2005,
        y: 66.8
      },
      {
        x: 2006,
        y: 67.8
      },
      {
        x: 2007,
        y: 73.6
      },
      {
        x: 2010,
        y: 77.7
      },
      {
        x: 2013,
        y: 73.7
      },
      {
        x: 2016,
        y: 85.6
      },
      {
        x: 2019,
        y: 72
      }
    ]
  },
  {
    id: 'mobility',
    color: '#6a2268',
    data: [
      {
        x: 2005,
        y: 0
      },
      {
        x: 2006,
        y: 0
      },
      {
        x: 2007,
        y: 0
      },
      {
        x: 2010,
        y: 0
      },
      {
        x: 2013,
        y: 33.5
      },
      {
        x: 2016,
        y: 57.3
      },
      {
        x: 2019,
        y: 72.8
      }
    ]
  },
  {
    id: 'seo',
    color: '#d93348',
    data: [
      {
        x: 2005,
        y: 62.8
      },
      {
        x: 2006,
        y: 63.8
      },
      {
        x: 2007,
        y: 67.7
      },
      {
        x: 2010,
        y: 61.2
      },
      {
        x: 2013,
        y: 54.5
      },
      {
        x: 2016,
        y: 71.3
      },
      {
        x: 2019,
        y: 64
      }
    ]
  },
  {
    id: 'search',
    color: '#009bc6',
    data: [
      {
        x: 2005,
        y: 55.5
      },
      {
        x: 2006,
        y: 59.9
      },
      {
        x: 2007,
        y: 78.6
      },
      {
        x: 2010,
        y: 51.9
      },
      {
        x: 2013,
        y: 49.2
      },
      {
        x: 2016,
        y: 60.2
      },
      {
        x: 2019,
        y: 58
      }
    ]
  },
  {
    id: 'interactivity',
    color: '#f6841f',
    data: [
      {
        x: 2005,
        y: 0
      },
      {
        x: 2006,
        y: 0
      },
      {
        x: 2007,
        y: 47.4
      },
      {
        x: 2010,
        y: 39.5
      },
      {
        x: 2013,
        y: 51.8
      },
      {
        x: 2016,
        y: 64.2
      },
      {
        x: 2019,
        y: 87.3
      }
    ]
  },
  {
    id: 'content',
    color: '#6eae43',
    data: [
      {
        x: 2005,
        y: 64.2
      },
      {
        x: 2006,
        y: 75.5
      },
      {
        x: 2007,
        y: 59.6
      },
      {
        x: 2010,
        y: 70.3
      },
      {
        x: 2013,
        y: 70.6
      },
      {
        x: 2016,
        y: 81.2
      },
      {
        x: 2019,
        y: 83
      }
    ]
  },
  {
    id: 'bios',
    color: '#005b95',
    data: [
      {
        x: 2005,
        y: 60.1
      },
      {
        x: 2006,
        y: 67.1
      },
      {
        x: 2007,
        y: 70.8
      },
      {
        x: 2010,
        y: 65.5
      },
      {
        x: 2013,
        y: 75.3
      },
      {
        x: 2016,
        y: 80.7
      },
      {
        x: 2019,
        y: 72.3
      }
    ]
  },
  {
    id: 'navigation',
    color: '#009b9a',
    data: [
      {
        x: 2005,
        y: 79.8
      },
      {
        x: 2006,
        y: 80
      },
      {
        x: 2007,
        y: 85.6
      },
      {
        x: 2010,
        y: 86.5
      },
      {
        x: 2013,
        y: 70.9
      },
      {
        x: 2016,
        y: 65.4
      },
      {
        x: 2019,
        y: 94.9
      }
    ]
  },
  {
    id: 'design',
    color: '#862577',
    data: [
      {
        x: 2005,
        y: 77.6
      },
      {
        x: 2006,
        y: 85.7
      },
      {
        x: 2007,
        y: 86.4
      },
      {
        x: 2010,
        y: 87.2
      },
      {
        x: 2013,
        y: 77.4
      },
      {
        x: 2016,
        y: 54.8
      },
      {
        x: 2019,
        y: 78.8
      }
    ]
  },
  {
    id: 'message',
    color: '#ea3818',
    data: [
      {
        x: 2005,
        y: 75.7
      },
      {
        x: 2006,
        y: 65.7
      },
      {
        x: 2007,
        y: 57.1
      },
      {
        x: 2010,
        y: 80.1
      },
      {
        x: 2013,
        y: 72.6
      },
      {
        x: 2016,
        y: 66.2
      },
      {
        x: 2019,
        y: 79.1
      }
    ]
  }
]
