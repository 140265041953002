// extracted by mini-css-extract-plugin
export var black = "index-module--black--2cc23";
export var bounce = "index-module--bounce--168c1";
export var chart = "index-module--chart--b8036";
export var content = "index-module--content--ed694";
export var dotMoveLeft = "index-module--dot-move-left--95b96";
export var dotMoveRight = "index-module--dot-move-right--a1efc";
export var gradient = "index-module--gradient--a1343";
export var group1 = "index-module--group1--3c7ca";
export var group2 = "index-module--group2--0e33e";
export var group3 = "index-module--group3--8dce4";
export var overlay = "index-module--overlay--a5bdc";
export var separatorWidth = "index-module--separator-width--ad404";
export var white = "index-module--white--10268";