import React from 'react'
import {ResponsiveLine} from '@nivo/line'

import {historicalScores} from 'utils/scores'

/**
 * Render the HistoricalScores component.
 * @see     {@link https://nivo.rocks/line/}
 * @author  Content Pilot
 * @return {Element} The HistoricalScores component.
 */
export default function HistoricalScores() {
  return (
    <ResponsiveLine
      data={historicalScores}
      margin={{top: 50, right: 50, bottom: 50, left: 50}}
      curve="cardinal"
      lineWidth={3}
      enableSlices="x"
      axisBottom={{
        legend: 'years',
        legendOffset: 40,
        legendPosition: 'middle'
      }}
      axisLeft={{
        legend: 'scores',
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      colors={(d) => d.color}
      theme={{
        fontSize: 13,
        axis: {
          legend: {
            text: {
              fontSize: 17,
              fontWeight: 500
            }
          }
        }
      }}
      pointSize={10}
      pointColor={{theme: 'background'}}
      pointBorderWidth={2}
      pointBorderColor={{from: 'serieColor'}}
      pointLabel="y"
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          translateX: 0,
          translateY: -10,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  )
}
