import React from 'react'
import {Link} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {nav, left, right, indicator, icon} from './HomeNavigation.module.scss'

/**
 * Render the HomeNavigation component.
 * @author  Content Pilot
 * @return {Element} The HomeNavigation component.
 */
export default function HomeNavigation() {
  return (
    <nav className={nav}>
      <div className={left}>
        <h3>Start Your Journey</h3>
      </div>

      <Link to="/communicating-your-message" rel="next" className={right}>
        <div>
          <span className={indicator}>Next</span>
          <h3>Communicating Your Message</h3>
        </div>
        <FontAwesomeIcon
          className={icon}
          icon={['fal', 'long-arrow-right']}
          aria-label="Arrow right"
        />
      </Link>
    </nav>
  )
}
